<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="text">员工账号列表</div>
      </div>
      <el-button type="primary" @click="add">新增</el-button>
      <div class="table-box">
        <el-table v-loading="loading" :data="queryTable.table" style="width: 100%" tooltip-effect="light">
          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="name" label="员工姓名"></el-table-column>
          <el-table-column prop="userName" label="账号"></el-table-column>
          <el-table-column prop="lastLoginTime" label="上次登陆时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" style="color: #135694" type="text">编辑</el-button>
              <el-button @click="del(scope.row.id)" style="color: #135694" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top: 25px">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :page-size="queryTable.pageSize"
            :total="queryTable.count"
            :current-page.sync="queryTable.page"
            @current-change="queryTable.search()"
          ></el-pagination>
        </el-row>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" title="员工账号" :visible.sync="saveDialogVisable" :append-to-body="true">
      <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="107px" label-position="left">
        <el-row :gutter="28">
          <el-col :span="12">
            <el-form-item label="员工姓名" prop="name">
              <el-input v-model="saveData.name" placeholder="请输入姓名" :maxlength="10"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <el-form-item label="账号" prop="userName">
              <el-input v-model="saveData.userName" placeholder="请输入账号" :maxlength="11"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="28">
          <el-col :span="12">
            <el-form-item label="密码" prop="password">
              <el-input v-model="saveData.password" placeholder="请输入密码" show-password :maxlength="10"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="saveDialogVisable = false">取消</el-button>
        <el-button type="primary" @click="save()" >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import validate from '@/assets/js/validate';

import { QueryTable } from '@/assets/js/QueryTable';
export default {
  name: 'UserManage',
  data() {
    let validatorTelephone = (rule, value, callback) => {
      if (value === '' || value === undefined) {
        callback(new Error('请输入手机号'));
        return;
      }
      if (validate.isValidPhone(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号'));
      }
    };
    let validatorIDNumber = (rule, value, callback) => {
      if (value === '' || value === undefined) {
        callback(new Error('请输入学生身份证号'));
        return;
      }
      if (validate.isValidIDNumber(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的身份证号'));
      }
    };
    return {
      fieldValue: [],
      loading: false,
      state: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 0,
          label: '未发布',
        },
        {
          value: 1,
          label: '已发布',
        },
      ],
      saveDialogVisable: false,
      saveData: {},
      saveInitData: {
        name: '',
        userName: '',
        password: '',
      },
      saveRules: {
        name: [{ required: true, message: '请输入员工姓名', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入员工账号', trigger: 'blur' }],
        password: [{ required: false, message: '请输入密码', trigger: 'blur' }],
      },
      queryTable: new QueryTable('userList'),
    };
  },
  methods: {
    add() {
      this.saveDialogVisable = true;
      this.$nextTick(() => {
        this.$refs['saveForm'].resetFields();
        this.saveData = JSON.parse(JSON.stringify(this.saveInitData));
      });
    },
    edit(row) {
      this.saveDialogVisable = true;
      this.$nextTick(() => {
        this.$refs['saveForm'].resetFields();
        this.saveData = JSON.parse(JSON.stringify(row));
      });
    },
    save() {
      this.$refs['saveForm'].validate((valid) => {
        if (valid) {
          if (this.saveData.id) {
            this.$api.userEdit(this.saveData).then((res) => {
              if (res.success) {
                this.$message.success({ message: '修改成功', offset: 80 });
                this.saveDialogVisable = false;
                this.queryTable.search();
              } else {
                this.$message.error({ message: res.message, offset: 80 });
              }
            });
          } else {
            this.$api.userEdit(this.saveData).then((res) => {
              if (res.success) {
                this.$message.success({ message: '新增成功', offset: 80 });
                this.saveDialogVisable = false;
                this.queryTable.search();
              } else {
                this.$message.error({ message: res.message, offset: 80 });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    del(id) {
      this.$confirm(`确定删除此数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$api.delemployeechilddeclarat(id).then((res) => {
          if (res.success) {
            this.$message.success({ message: '删除成功', offset: 80 });
            this.queryTable.search();
          } else {
            this.$message.error({ message: res.message, offset: 80 });
          }
        });
      });
    },
    formatterIdNumber(row) {
      var tel = row.studentNum + '';
      return tel.replace(tel.substring(6, 14), '********');
    },
    formatterTelephone(row) {
      var tel = row.parentPhone + '';
      return tel.replace(tel.substring(3, 7), '****');
    },
  },
  created() {
    this.queryTable.search();
  },
};
</script>

<style lang='scss' scoped>
.box {
  .content {
    padding: 20px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    .table-box {
      margin-top: 20px;
    }
    .file {
      margin-top: -5px;
      display: flex;
      font-size: 18px;
      color: #000000;
      line-height: 40px;
      margin-left: 14px;
      font-weight: 600;
    }
  }
}
</style>
